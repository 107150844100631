import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "When is our next election?",
  "description": null,
  "author": "Diego",
  "categories": ["faqs"],
  "date": "2021-11-01T00:00:00.000Z",
  "featured": false,
  "tags": [],
  "relatedArticles": ["where-do-i-request-an-absentee-ballot"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul">{`November 2nd, 2021: General Election and Special Election`}</li>
      <li parentName="ul">{`November 30th, 2021: General Election and Special Election Runoffs`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://sos.ga.gov/admin/uploads/2021_State_Calendar_(Short)4.pdf"
      }}>{`https://sos.ga.gov/admin/uploads/2021_State_Calendar_(Short)4.pdf`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      